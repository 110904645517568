import React, { Component } from 'react';
import '../styles/app.css';
import '../styles/game.css';

/* constantes */

let numberOfQuestions = 0;
let currentQuestion = {};
let acceptingAnswers = false;
let count = 0;
let score = 0;
let multiscore = {};
let questionCounter = 0;
let availableQuestions = [];

let questions = [];

class Game extends Component {

  	constructor(props) {
	    super(props);
	    const data = JSON.parse(sessionStorage.getItem("program")) || null;
	    this.state = { 
	    	program: data,
	    	ready: false,
	    	scoreText: 0
	    };
	}

	componentDidMount() {
	    const { program } = this.state;
	    this.loadAnswers(program);
	}

	insert = (arr, index, newItem) => [
	  ...arr.slice(0, index),
	  newItem,
	  ...arr.slice(index)
	]

	shuffle = (array) => {
	  var currentIndex = array.length, temporaryValue, randomIndex;
	  while (0 !== currentIndex) {
	    randomIndex = Math.floor(Math.random() * currentIndex);
	    currentIndex -= 1;
	    temporaryValue = array[currentIndex];
	    array[currentIndex] = array[randomIndex];
	    array[randomIndex] = temporaryValue;
	  }
	  return array;
	}

	showScores = (program) => {
		var questionStyle = {};
		var scoreStyle = {};
	    if (program && program.options && program.options.scoring && !program.options.multiples) {
	        questionStyle.margin = "0 0 40px 0";
	        scoreStyle.display = "block";
	    } else {
	        questionStyle.margin = "0 0 40px 0";
	        scoreStyle.display = "none";
	    }
	    this.setState({questionStyle: questionStyle, scoreStyle: scoreStyle});
	}

	loadAnswers = (program) => {
	    this.showScores(program);
	    numberOfQuestions = program.questions.length;
	    questions = program.questions.map(loadedQuestion => {
	        const formattedQuestion = { question: loadedQuestion.question };
	        let answerChoices = undefined;
	        if (loadedQuestion.incorrect_answers !== undefined) {
	            let answersShuffled = loadedQuestion.incorrect_answers;
	            if (program.options && program.options.shuffle) {
	                let idxs = [];
	                for (let i in answersShuffled)
	                    idxs.push(i);
	                this.shuffle(idxs);
	                answersShuffled = [];
	                for (let i in idxs) 
	                    answersShuffled.push(loadedQuestion.incorrect_answers[idxs[i]]);
	            }
	            answerChoices = [...answersShuffled];
	            formattedQuestion.optionsQuestions = answerChoices.length + 1;
	            formattedQuestion.answer = Math.floor(Math.random() * formattedQuestion.optionsQuestions * 0.99999) + 1;
	            answerChoices = this.insert(answerChoices, formattedQuestion.answer - 1, loadedQuestion.correct_answer);
	            formattedQuestion.scores = new Array(answersShuffled.length).fill(0);
	            formattedQuestion.scores = this.insert(formattedQuestion.scores, formattedQuestion.answer - 1, 1);
	        } else {
	            let answersShuffled = loadedQuestion.answers;
	            let scoresShuffled = loadedQuestion.scores;
	            if (answersShuffled.length != scoresShuffled.length) {
	                alert("Erro! O número de respostas deve sempre coincidir com o número de scores dentro de uma pergunta. Verifique inconsistências em '" + JSON.stringify(answersShuffled) + "'.")
	                return;
	            }
	            if (program.options && program.options.shuffle) {
	                let idxs = [];
	                for (let i in answersShuffled)
	                    idxs.push(i);
	                this.shuffle(idxs);
	                answersShuffled = [];
	                scoresShuffled = [];
	                for (let i in idxs) {
	                    answersShuffled.push(loadedQuestion.answers[idxs[i]]);
	                    scoresShuffled.push(loadedQuestion.scores[idxs[i]]);
	                }
	            }
	            answerChoices = [...answersShuffled];
	            formattedQuestion.optionsQuestions = answerChoices.length;
	            if (!program.options.multiples) {
		            const max = Math.max(...scoresShuffled);
		            const min = Math.min(...scoresShuffled);
		            for (let i in scoresShuffled)
		                scoresShuffled[i] = max != min ? (scoresShuffled[i] - min) / (max - min) : 1 / answerChoices.length;
	            }
	            formattedQuestion.scores = scoresShuffled;
	        }

	        answerChoices.forEach((choice, index) => {
	            formattedQuestion["choice" + (index + 1)] = choice;
	        })
	        return formattedQuestion;
	    }); 
	    questionCounter = 0;
	    score = 0;
	    availableQuestions = [...questions];
	    this.getNewQuestion();
	    this.setState({ready: true})
	}

	getNewQuestion = () => {
		const { program } = this.state;
	    if(availableQuestions.length === 0 || questionCounter >= numberOfQuestions){
	        sessionStorage.setItem("lastScore", score);
	        sessionStorage.setItem("lastMultiScore", JSON.stringify(multiscore));
	        /*vá para a página final*/
	        return window.location.assign("/quiz/end");
	    }
	    questionCounter++;
	    let pt = `Questões ${questionCounter}/${numberOfQuestions}`;
	    let pbf = `${(questionCounter / numberOfQuestions) * 100}%`;
	    const questionIndex = (program.options && program.options.randomized) ? Math.floor(Math.random() * availableQuestions.length) : 0;
	    currentQuestion = availableQuestions[questionIndex];

	    this.setState({progressText: pt, progressBarFull: pbf, question: currentQuestion.question});

	    availableQuestions.splice(questionIndex, 1);
	    acceptingAnswers = true;
	};

	onChoiceClick = (e, selectAnswer) => {
		if(!acceptingAnswers) return;
        acceptingAnswers = false;
        const selectedChoice = e.target;
        this.incrementScore(currentQuestion.scores[selectAnswer - 1]);
        const classToApply = currentQuestion.answer !== undefined ? selectAnswer == currentQuestion.answer ? 'correct' : 'incorrect' : 'neutral';
        selectedChoice.parentElement.classList.add(classToApply);
        setTimeout( () => {
            selectedChoice.parentElement.classList.remove(classToApply);
            this.getNewQuestion();
        }, 750);
	}

	incrementScore = val => {
		if (typeof(val) == "object") {
			for (let i in val) {
				if (multiscore[i] == undefined)
					multiscore[i] = new Number(val[i]);
				else
					multiscore[i] += new Number(val[i]);
			}
		} else {
		    count += val;
		    score = this.normalizeScore(count);
		}
	    this.setState({scoreText: score});
	}

	normalizeScore = (c) => {
	    return Math.round(10000 * c/numberOfQuestions)/100;
	}

	render() {
		if (!this.state.ready) {
			return <table className="commonQuiz">
				<tbody>
					<tr>
						<td className="cell-body">
					    	<div className="container">
				                <div id="loader" key="loader"></div>
				            </div>
			    		</td>
			    	</tr>
			    </tbody>
		    </table>
		}
		return <table className="commonQuiz">
			<tbody>
				<tr>
					<td className="cell-body">
				    	<div className="container">
			                <div key="game" className="justify-center flex-column">
			                    <div id="hud" key="hud">
			                        <div key="hud-item-question" style={this.state.questionStyle}>
			                            <p key="progressText" className="hud-prefix">
			                                {this.state.progressText}
			                            </p> 
			                            <div id="progressBar" key="progressBar">
			                                <div id="progressBarFull" key="progressBarFull" style={{width:this.state.progressBarFull}}></div>
			                            </div>             
			                        </div>
			                        <div key="hud-item-score" style={this.state.scoreStyle}>
			                            <p className="hud-prefix">
			                                Pontuação
			                            </p>        
			                            <h1 className="h1Quiz hud-main-text" key="score">
			                                {this.state.scoreText}
			                            </h1>                
			                        </div>
			                    </div>
			                    <h3 className="h3Quiz" key="questions" style={{marginBottom:40, fontSize: "2rem", textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: this.state.question }}></h3>
			                    {Object.keys(currentQuestion).filter((item)=>item.startsWith("choice")).map((item, idx)=>{
				                    return <div className="choice-container" onClick={(e)=>this.onChoiceClick(e, idx+1)}>
				                        <p className="choice-prefix">{idx + 1}.</p>
				                        <p className="choice-text">{currentQuestion[item]}</p>
				                    </div>
			                    })}
			                </div>
				    	</div>
		    		</td>
		    	</tr>
		    </tbody>
	    </table>
	}
}

export default Game;