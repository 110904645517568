import React, { Component } from 'react';
import { loadCode } from "./compiler.js"
import Toggle from 'react-toggle'
import tup from "./tup.png"
import tdown from "./tdown.png"
import { backend, frontend } from  './config.js'

export default class Send extends Component {
	
	constructor(props) {
		super(props);
		this.state = this.init();
		this.sendRequest = this.sendRequest.bind(this)
	}

	init() {
		return {
			identifier: "",
			content: "",
			original: "",
			code: "",
			info: {},
			statusMsg: "",
			file: undefined,
			status: undefined,
		}
	}

	mountInfo(value, f) {
		var { info } = this.state; 
		info[f] = value;
		this.setState({info})
	}

	validateIdentifier(e) {
		var val = e.target.value.toLowerCase();
		val = val.replace(/[^a-z0-9_]/g, "");
		val = val.replace(/ /g, "_");
		this.setState({identifier: val})
	}

	sendRequest() {
		var { identifier, original, code, info } = this.state;
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();
			const formData = new FormData();
    		info = info ? JSON.stringify(info) : {}
    		code = JSON.stringify(code);
			formData.append("identifier", identifier);
			formData.append("code", code);
			formData.append("content", original);
			formData.append("info", info);
			req.open("POST", backend().path + backend().port + "/upload");
			req.send(formData);
		});
	}

	getAvatarCode = async (file) => {
		if (!file)
			return;
    	var { info } = this.state;
		var fileReader = new FileReader();
		fileReader.onloadend = await function () {
    		info.avatar = fileReader.result;
  		}
		fileReader.readAsDataURL(file);
		this.setState({info})
	}

	handleRead = (e, fileReader) => {
		const { identifier } = this.state;
		if (identifier == "")
			return;
		var original = fileReader.result;
		var code = "", statusMsg = "";
		var status = undefined;
		var linha, coluna, termo, tipo;
		var content = original.replace(/\r/g, "");
		try {
			var code = loadCode(content)
			status = true;
			statusMsg = frontend().path + frontend().port + "/bot?id=" + identifier
		} catch (error) {
			console.log(error)
			code = "";
			status = false;
			if (error.message.startsWith("Erro de semântica") && !error.message.includes("Erro de semântica: ocorreu um erro inesperado ao tentar usar um método")) {
				var matches = error.message.match(/'[^']*'/g);
				if (matches && matches.length > 0) 
	          		termo = matches[0].substring(1,matches[0].length-1);
	          	tipo = "semantic";
			} else if (error.message.includes("linha") && error.message.includes("coluna")) {
				var matches = error.message.match(/\d+/g); 
	          	linha = new Number(matches[0]);
	          	coluna = new Number(matches[1]);
	          	var matches = error.message.match(/'[^']*'/g);
				if (matches && matches.length > 0) 
	          		termo = matches[matches.length-1].substring(1,matches[matches.length-1].length-1);
	          	tipo = "syntax";
			}
			statusMsg = error.message
		}
		var pieces = content.split(/\n/);
		var lin = 1;
		for (let i in pieces) {
			if (tipo == "syntax" && linha == lin) {
				pieces[i] = pieces[i].substring(0, coluna-1) + "<strong style=\"background:red; color:white\">" + termo + "</strong>" + pieces[i].substring(coluna + termo.length - 1)
			}
			pieces[i] = "<strong style=\"margin-right:5px; font-size:8pt; color:blue\">" + (lin++) + ".</strong>" + pieces[i];
		}
		content = pieces.join("<br />");
		if (tipo == "semantic") {
			//var idx = content.indexOf(termo);
			//content = content.substring(0, idx) + "<strong style=\"background:red; color:white\">" + termo + "</strong>" + content.substring(idx + termo.length)
		}
		content = content.replace(/\t/g, "&emsp;");
		
		this.setState({original, content, code, status, statusMsg})
		if (identifier != "" && code != "") {
			this.sendRequest();
		} 
	}

	handleFile = (file) => {
    	const { status, identifier } = this.state;
		if (!file) {
			this.setState({status: false, statusMsg: "Sem arquivo anexado!"})
			return;
		}

		if (identifier == "") {
			this.setState({status: false, statusMsg: "Sem identificador definido!"})
			return;
		}

		var fileReader = new FileReader();
		fileReader.onloadend = (e) => this.handleRead(e, fileReader);
		fileReader.readAsText(file);
	}

    render() {
    	const { content, status, identifier, info, statusMsg } = this.state;
    	const imgStyle = {width: 98, marginLeft: "25%"};
        return (
            <div className="container" style={{maxWidth: "unset"}}>
            	<table style={{width:"100%"}}>
	            	<tbody>
		            	<tr style={{width:"100%"}}>
			            	<td style={{width:"100%"}}>
				                <div className="row" style={{width:"100%"}}>
				                    <form>
				                        <h3>Chatbot</h3>
						            	<table style={{width:"100%", borderSpacing: 12, borderCollapse: "separate"}}>
							            	<tbody>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor="delay">Tempo do bot (ms)</label></td>
				                            		<td><input id="delay" type="text" onChange={e => {this.mountInfo(e.target.value, "botDelay")}} value={info.botDelay !== undefined ? info.botDelay : "200"}/></td>
	            						            <td rowSpan={9} style={{width:"70%"}}>
										                <div id="content" 
										                	style={{borderRadius:10, border:"1px solid black", height:"calc(100vh - 200px)", fontFamily:"monospace", fontSize: "9.5pt", padding:25, overflow: "auto"}}
										                	dangerouslySetInnerHTML={{__html: content}}
										                />
										            </td>
				                            	</tr>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor='cache'>Cache?</label></td>
				                            		<td><Toggle id='cache' defaultChecked={info.cache !== undefined ? info.cache : true} onChange={e => this.mountInfo(e.target.checked, "cache")} /></td>
				                            	</tr>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor='floating'>Bot flutuante?</label></td>
				                            		<td><Toggle id='floating' defaultChecked={info.floating !== undefined ? info.floating : false} onChange={e => this.mountInfo(e.target.checked, "floating")} /></td>
				                            	</tr>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor='withavatar'>Com avatar?</label></td>
				                            		<td><Toggle id='withavatar' defaultChecked={info.hideBotAvatar !== undefined ? info.hideBotAvatar : true} onChange={e => {this.mountInfo(!e.target.checked, "hideBotAvatar"); this.mountInfo(!e.target.checked, "hideUserAvatar")}} /></td>
				                            	</tr>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor='speechSynthesis'>Reconhecimento de fala?</label></td>
				                            		<td><Toggle id='speechSynthesis' defaultChecked={info.speechSynthesis !== undefined ? info.speechSynthesis : false} onChange={e => this.mountInfo(e.target.checked, "speechSynthesis")} /></td>
				                            	</tr>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor='recognitionEnable'>Síntese de fala?</label></td>
				                            		<td><Toggle id='recognitionEnable' defaultChecked={info.recognitionEnable !== undefined ? info.recognitionEnable : false} onChange={e => this.mountInfo(e.target.checked, "recognitionEnable")} /></td>
				                            	</tr>
								            	<tr>
									            	<td colSpan={2}>
										            	<label htmlFor='avt'>Imagem do avatar</label>
							                            <input id="avt" type="file" onChange={e => this.getAvatarCode(e.target.files[0])}/>
									            	</td>
				                            	</tr>
												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor da fonte do bot</label></td>
													<td><input id="botFontColor" type="color" onChange={e => {this.mountInfo(e.target.value, "botFontColor")}} value={info.botFontColor !== undefined ? info.botFontColor : '#ffffff'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor da fonte do header</label></td>
													<td><input id="headerFontColor" type="color" onChange={e => {this.mountInfo(e.target.value, "headerFontColor")}} value={info.headerFontColor !== undefined ? info.headerFontColor : '#ffffff'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor da fonte do usuário</label></td>
													<td><input id="userFontColor" type="color" onChange={e => {this.mountInfo(e.target.value, "userFontColor")}} value={info.userFontColor !== undefined ? info.userFontColor : '#003366'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor da fonte das opções</label></td>
													<td><input id="botBubbleColor" type="color" onChange={e => {this.mountInfo(e.target.value, "botBubbleColor")}} value={info.botBubbleColor !== undefined ? info.botBubbleColor : '#003366'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor da bolha do usuário</label></td>
													<td><input id="userBubbleColor" type="color" onChange={e => {this.mountInfo(e.target.value, "userBubbleColor")}} value={info.userBubbleColor !== undefined ? info.userBubbleColor : '#ffffff'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor da bolha das opções</label></td>
													<td><input id="optionBubbleColor" type="color" onChange={e => {this.mountInfo(e.target.value, "optionBubbleColor")}} value={info.optionBubbleColor !== undefined ? info.optionBubbleColor : '#3399CC'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor do fundo do body</label></td>
													<td><input id="background" type="color" onChange={e => {this.mountInfo(e.target.value, "background")}} value={info.background !== undefined ? info.background : '#f5f8fb'}/></td>
												</tr>

												<tr>
													<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">Cor do fundo do header</label></td>
													<td><input id="headerBgColor" type="color" onChange={e => {this.mountInfo(e.target.value, "headerBgColor")}} value={info.headerBgColor !== undefined ? info.headerBgColor : '#0099CC'}/></td>
												</tr>
								            	<tr>
									            	<td style={{whiteSpace: "nowrap"}}><label htmlFor="ident">*Identificador</label></td>
				                            		<td><input id="identifier" type="text" onChange={e => this.validateIdentifier(e)} value={identifier}/></td>
				                            	</tr>
								            	<tr>
									            	<td colSpan={2}>
										            	<label htmlFor='fil'>*Código-fonte</label>
							                            <input id="fil" type="file" onChange={e => this.setState({file:e.target.files[0]})}/>
									            	</td>
				                            	</tr>
							            	</tbody>
						            	</table>
				                        <div className="form-group" style={{marginTop:10}}>
				                            <button style={{verticalAlign: "top"}} className="btn btn-primary" type="button" onClick={e => this.handleFile(this.state.file)}>Enviar</button>
					                        {status===true  && <><img style={{float:"right", height: 80}} src={tup} style={imgStyle}/> 
					                        <div><div className="btn-success" style={{float: "right", width: "60%", padding: 10}}>
					                        	O programa foi enviado com sucesso. Acesse o bot em <a style={{color: "#f5ee75"}} href={statusMsg} target="_blank">{statusMsg}</a></div>
					                        <br/><a href="#" style={{fontSize: 20}} onClick={()=>{this.setState(this.init())}}> Deseja subir outro programa?</a></div></>}
					                        {status===false && <><img style={{float:"right", height: 80}} src={tdown} style={imgStyle}/> <div className="btn-danger" style={{float: "right", width: "60%", padding: 10}}>{statusMsg}</div></>}
				                        </div>
				                    </form>
				                </div>
				            </td>
				        </tr>
				    </tbody>
			    </table>
            </div>
        )
    }
}