import React, { Component } from 'react';
import logo from "./img/inicioquiz.png";
import './src/styles/app.css';
import { backend } from  './config.js';

class SendQuiz extends Component {
  	constructor(props) {
	    super(props);
	    this.state = { 
	    	file: undefined
	    };
	}

	handleFile = (file) => {
      	sessionStorage.removeItem("program");
      	sessionStorage.removeItem("id");
      	sessionStorage.removeItem("lastScore");
    	const { status, identifier } = this.state;
		if (!file) {
			this.setState({status: false, statusMsg: "Sem arquivo anexado!"})
			return;
		}

		if (identifier == "") {
			this.setState({status: false, statusMsg: "Sem identificador definido!"})
			return;
		}

	    var filename = file.name;
		var fileReader = new FileReader();
		fileReader.onloadend = (e) => {
			var data = JSON.parse(e.target.result);
	        if(data.id == undefined) {
	            data.id = filename.substring(0, filename.lastIndexOf("."));
	        }
			const req = new XMLHttpRequest();
			req.open("POST", backend() + "/save");
			req.send(JSON.stringify(data));
			req.onreadystatechange=function() {
			    if (req.readyState === 4){   //if complete
			        if(req.status === 200){  //check if "OK" (200)
			            alert("Arquivo salvo com sucesso!")
			        } else {
			            alert("O arquivo não pôde ser salvo!")
			        }
			    } 
			}
		};
		fileReader.readAsText(file);
	}

	render() {
		return <table>
			<tbody>
				<tr>
					<td className="cell-body">
						<a><img src={logo} width="200" height="200"/></a>
						<h2 style={{fontSize:"19pt"}}>Informe o nome do arquivo que se deseja subir.</h2>
						<div style={{width: "100%", marginBottom: 30}}>
						<input type='file' accept='application/json' id='file-input' style={{width: "50%"}} onChange={e => this.setState({file:e.target.files[0]})}/>
						<button className="btn btn-primary" id='add-file-btn' style={{width: 80, height: 50, fontWeight: 600, fontFamily: "monospace", marginLeft: 20}}
							onClick={e => this.handleFile(this.state.file)}> Enviar </button> 
						</div>
						<h3 style={{fontSize:"16pt"}}>Caso não haja <b>id</b> no json do arquivo, o nome do mesmo será o identificador do quiz. <br/> 
						<b>Não</b> use <b>acentos</b> nem <b>espaços em branco</b> no nome! <br/> 
						Anote esse nome e guarde-o com segurança.</h3>
		    		</td>
		    	</tr>
		    </tbody>
	    </table>
	}
}

export default SendQuiz;