import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Option from './Option';
import OptionElement from './OptionElement';
import Options from './Options';
import OptionsStepContainer from './OptionsStepContainer';

class OptionsStep extends Component {
  constructor(props) {
    super(props);
    this.values = [];
    this.state = {
      update: false
    }
  }

  addOptionClick = (value) => {
    var idx = this.values.indexOf(value);
    if (idx == -1)
      this.values.push(value);
    else
      this.values.splice(idx,1);
    this.setState({update: true})
  };

  onOptionClick = ({ value }) => {
    const { triggerNextStep } = this.props;
    triggerNextStep({ value });
  };

  renderOption = option => {
    const { optionBubbleColor, step } = this.props;
    const { user, multiple } = step;
    const { submit, value, label } = option;
    const styleEmpty = { background:"#EEE", border: "solid 1px #333", color: "#333" };
    const styleFilled = { background:"#333", border: "solid 1px #333", color: "#EEE" };
    if (multiple && submit) {
      option.value = this.values;
      return (
        <Option key={label} className="rsc-os-option">
          <OptionElement
            className="rsc-os-option-element"
            style={optionBubbleColor}
            user={user}
            onClick={() => this.onOptionClick({ value })}
          >
            {label}
          </OptionElement>
        </Option>
      );
    }
    if (multiple && !submit) {
      return (
        <Option key={label} className="rsc-os-option">
          <OptionElement
            className="rsc-os-option-element"
            style={this.values.includes(value) ? styleFilled : styleEmpty}
            user={user}
            onClick={() => this.addOptionClick(value)}
          >
            {label}
          </OptionElement>
        </Option>
      );
    }
    return (
      <Option key={value} className="rsc-os-option">
        <OptionElement
          className="rsc-os-option-element"
          style={optionBubbleColor}
          user={user}
          onClick={() => this.onOptionClick({ value })}
        >        
          {label}
        </OptionElement>
      </Option>
    );
  };

  render() {
    const { step } = this.props;
    const { options } = step;
    return (
      <OptionsStepContainer className="rsc-os">
        <Options className="rsc-os-options" style={{float:"right", textAlign: "right"}}>
          {Object.keys(options).map(key => options[key]).map(this.renderOption)}
        </Options>
      </OptionsStepContainer>
    );
  }
}

OptionsStep.propTypes = {
  optionBubbleColor: PropTypes.objectOf(PropTypes.any).isRequired,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  triggerNextStep: PropTypes.func.isRequired
};

export default OptionsStep;
