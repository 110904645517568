export function normalizeDate(date) {
	var original = date;
	date = date.trim().toLowerCase();
	if (date.length == 0)
		return "";
	date = date.replace(/\./g, "/");
	date = date.replace(/-/g, "/");
	var pieces = [];
	if (date.includes(" ")) {
		var parts = date.split(" ");
		for (let i in parts) {
			if (parts[i] != "de" && parts[i] != "do" && parts[i] != "dia" && parts[i] != "mês" && parts[i] != "ano")
			pieces.push(parts[i]);
		}
	} else {
		pieces = date.split("/");
	}
	if (pieces.length >= 2) {
		var dia = pieces[0];
		if (!/\d+/.test(dia)) {
			if (dia == "primeiro" || dia == "um")
				dia = "01";
			else if (dia == "segundo" || dia == "dois")
				dia = "02";
			else if (dia == "terceiro" || dia == "três")
				dia = "03";
			else if (dia == "quarto" || dia == "quatro")
				dia = "04";
			else if (dia == "quinto" || dia == "cinco")
				dia = "05";
			else if (dia == "sexto" || dia == "seis")
				dia = "06";
			else if (dia == "sétimo" || dia == "sete")
				dia = "07";
			else if (dia == "oitavo" || dia == "oito")
				dia = "08";
			else if (dia == "nono" || dia == "nove")
				dia = "09";
			else if (dia == "décimo" || dia == "dez")
				dia = "10";
			else if (dia == "décimo/primeiro" || dia == "onze")
				dia = "11";
			else if (dia == "décimo/segundo" || dia == "doze")
				dia = "12";
			else if (dia == "décimo/terceiro" || dia == "treze")
				dia = "13";
			else if (dia == "décimo/quarto" || dia == "quatorze" || dia == "catorze")
				dia = "14";
			else if (dia == "décimo/quinto" || dia == "quinze")
				dia = "15";
			else if (dia == "décimo/sexto" || dia == "dezesseis")
				dia = "16";
			else if (dia == "décimo/sétimo" || dia == "dezessete")
				dia = "17";
			else if (dia == "décimo/oitavo" || dia == "dezoito")
				dia = "18";
			else if (dia == "décimo/nono" || dia == "dezenove")
				dia = "19";
			else if (dia == "vigésimo" || dia == "vinte")
				dia = "20";
			else if (dia == "vigésimo/primeiro" || dia == "vinte/um")
				dia = "21";
			else if (dia == "vigésimo/segundo" || dia == "vinte/dois")
				dia = "22";
			else if (dia == "vigésimo/terceiro" || dia == "vinte/três")
				dia = "23";
			else if (dia == "vigésimo/quarto" || dia == "vinte/quatro")
				dia = "24";
			else if (dia == "vigésimo/quinto" || dia == "vinte/cinco")
				dia = "25";
			else if (dia == "vigésimo/sexto" || dia == "vinte/seis")
				dia = "26";
			else if (dia == "vigésimo/sétimo" || dia == "vinte/sete")
				dia = "27";
			else if (dia == "vigésimo/oitavo" || dia == "vinte/oito")
				dia = "28";
			else if (dia == "vigésimo/nono" || dia == "vinte/nove")
				dia = "29";
			else if (dia == "trigésimo" || dia == "trinta")
				dia = "30";
			else if (dia == "trigésimo/primeiro" || dia == "trinta/um")
				dia = "31";
			else
				return original;
		} else if (dia.length == 1) {
			dia = "0" + dia;
		}
		var mes = pieces[1];
		if (mes.length == 1) 
			mes = "0" + mes;
		else if (!/\d+/.test(mes)) {
			if (mes == "janeiro" || mes == "jan" || mes == "um" || mes == "primeiro")
				mes = "01";
			else if (mes == "fevereiro" || mes == "fev" || mes == "dois" || mes == "segundo")
				mes = "02";
			else if (mes == "março" || mes == "mar" || mes == "três" || mes == "terceiro")
				mes = "03";
			else if (mes == "abril" || mes == "abr" || mes == "quatro" || mes == "quarto")
				mes = "04";
			else if (mes == "maio" || mes == "mai" || mes == "cinco" || mes == "quinto")
				mes = "05";
			else if (mes == "junho" || mes == "jun" || mes == "seis" || mes == "sexto")
				mes = "06";
			else if (mes == "julho" || mes == "jul" || mes == "sete" || mes == "sétimo")
				mes = "07";
			else if (mes == "agosto" || mes == "ago" || mes == "oito" || mes == "oitavo")
				mes = "08";
			else if (mes == "setembro" || mes == "set" || mes == "nove" || mes == "nono")
				mes = "09";
			else if (mes == "outubro" || mes == "out" || mes == "dez" || mes == "décimo")
				mes = "10";
			else if (mes == "novembro" || mes == "nov" || mes == "onze" || mes == "décimo/primeiro")
				mes = "11";
			else if (mes == "dezembro" || mes == "dez" || mes == "doze" || mes == "décimo/segundo")
				mes = "12";
			else
				return original;
		}
		var ano = new Date().getFullYear();
		if (pieces.length == 3) 
			ano = pieces[2];
		if (pieces.length > 3) 
			return original;
		if (/\d+/.test(ano)) {
			if (ano.length == 2) {
				if (ano >= "50")
					ano = "19" + ano;
				else
					ano = "20" + ano;
			}
		} else
			return original;
	}
	return dia + "/" + mes + "/" + ano;
}

export function normalizeTime(time) {
	var original = time;
	time = time.trim().toLowerCase();
	if (time.length == 0)
		return "";
	if (time == "meia-noite" || time == "meia noite")
		return "00:00";
	if (time == "meio-dia" || time == "meio dia")
		return "12:00";
	time = time.replace(/\./g, ":");
	time = time.replace(/-/g, ":");
	time = time.replace(/,/g, "");
	var afternoon = false;
	if (time.endsWith("pm"))
		afternoon = true;
	if (time.endsWith("am") || time.endsWith("pm"))
		time = time.substring(0, time.length-2);
	var pieces = [];
	var saidH = false, saidM = false, saidS = false;
	var dezena = false;
	if (time.includes(" ")) {
		var parts = time.split(" ");
		for (let i in parts) {
			if (parts[i] != "e" && parts[i] != "," && parts[i] != "às" && parts[i] != "as" && 
					parts[i] != "a" && parts[i] != "à" && parts[i] != "das" && parts[i] != "da") {
				if (parts[i] == "hora" || parts[i] == "horas") {
					if (saidH || saidM || saidS)
						return original;
					saidH = true;
				}
				else if (parts[i] == "minuto" || parts[i] == "minutos") {
					if (saidM || !saidH || saidS)
						return original;
					saidM = true;
				}
				else if (parts[i] == "segundo" || parts[i] == "segundos") {
					if (saidS || !saidH || !saidM)
						return original;
					saidS = true;
				} else if (parts[i] == "zero" || parts[i] == "0")
					pieces.push("00");
				else if (parts[i] == "uma" || parts[i] == "um" || parts[i] == "1") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "1"
						pieces.push(n)
					}
					else
						pieces.push("01");
				} else if (parts[i] == "duas" || parts[i] == "dois" || parts[i] == "2") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "2"
						pieces.push(n)
					}
					else 
						pieces.push("02");
				} else if (parts[i] == "três" || parts[i] == "3") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "3"
						pieces.push(n)
					}
					else 
						pieces.push("03");
				} else if (parts[i] == "quatro" || parts[i] == "4") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "4"
						pieces.push(n)
					}
					else 
						pieces.push("04");
				} else if (parts[i] == "cinco" || parts[i] == "5") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "5"
						pieces.push(n)
					}
					else 
						pieces.push("05");
				} else if (parts[i] == "seis" || parts[i] == "6") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "6"
						pieces.push(n)
					}
					else 
						pieces.push("06");
				} else if (parts[i] == "sete" || parts[i] == "7") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "7"
						pieces.push(n)
					}
					else 
						pieces.push("07");
				} else if (parts[i] == "oito" || parts[i] == "8") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "8"
						pieces.push(n)
					}
					else 
						pieces.push("08");
				} else if (parts[i] == "nove" || parts[i] == "9") {
					if (dezena) {
						dezena = false;
						var n = pieces.pop();
						n = n.substring(0,1) + "9"
						pieces.push(n)
					}
					else 
						pieces.push("09");
				} else if (parts[i] == "dez")
					pieces.push("10");
				else if (parts[i] == "onze")
					pieces.push("11");
				else if (parts[i] == "doze")
					pieces.push("12");
				else if (parts[i] == "treze")
					pieces.push("13");
				else if (parts[i] == "quatorze")
					pieces.push("14");
				else if (parts[i] == "quinze")
					pieces.push("15");
				else if (parts[i] == "dezesseis")
					pieces.push("16");
				else if (parts[i] == "dezessete")
					pieces.push("17");
				else if (parts[i] == "dezoito")
					pieces.push("18");
				else if (parts[i] == "dezenove")
					pieces.push("19");
				else if (parts[i] == "vinte") {
					dezena = true;
					pieces.push("20");
				} else if (parts[i] == "trinta") {
					dezena = true;
					pieces.push("30");
				} else if (parts[i] == "quarenta") {
					dezena = true;
					pieces.push("40");
				} else if (parts[i] == "cincoenta" || parts[i] == "cinquenta") {
					dezena = true;
					pieces.push("50");
				} else if (parts[i] == "noite" || parts[i] == "tarde")
					afternoon = true;
				else if (parts[i] == "madruga" || parts[i] == "madrugada") 
					afternoon = false;
				else if (parts[i] == "manhã" || parts[i] == "matina") 
					afternoon = false;
				//else if (parts[i] == "pra" || parts[i] == "para"  || parts[i] == "para") 
				//	pieces.push("-");
				else if (/\d+/.test(parts[i]))
					pieces.push(parts[i]);
				else 
					return original;
			} else if (parts[i] != "e" && parts[i] != ",")
				dezena = false;
		}
	} else {
		pieces = time.split(":"); // 5:30
	}
	if (pieces.length >= 1) {
		if (!/\d+/.test(pieces[0]) || pieces[0].length > 2)
			return original;
		if (pieces[0].length == 1)
			pieces[0] = "0" + pieces[0];
		if (pieces.length == 1)
			return pieces[0] + ":" + "00";
		if (!/\d+/.test(pieces[1]) || pieces[1].length > 2)
			return original;
		if (pieces[1].length == 1)
			pieces[1] = "0" + pieces[1];
		if (pieces.length == 2)
			return pieces[0] + ":" + pieces[1];
		if (pieces[2].length == 1)
			pieces[2] = "0" + pieces[2];
		if (pieces.length == 3)
			return pieces[0] + ":" + pieces[1] + ":" + pieces[2] ;
	}
	return original;
}