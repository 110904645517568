import React from 'react';
import teste from './data/teste.json'
import { backend } from './config.js'
import { useLocation } from "react-router-dom";
import GeoHeatmap from './geoHeatmap/GeoHeatmap';

function Map() {
	let idBot = useQuery(); 
	let title = "Potencial distribuição de contaminações COVID-19";
	let ref = "Potencial distribuição de contaminações COVID-19";
	return openMap(idBot, title);
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function openMap(idBot, title) {
	var code = undefined;
	try {
		var oReq = new XMLHttpRequest();
		oReq.open("GET", backend().path + backend().port + "/map?" + idBot, false);
		oReq.send();
		var data = JSON.parse(oReq.responseText);
		if (data.length > 0)
		    return <div style={{width:"90vw",height:"90vh"}}>
		    	<div className="row" style={{position:"absolute", left:"10vw", top:"5vh", width:"80vw",height:"80vh"}}>
					<GeoHeatmap addressPoints={data} title={title}/>
				</div>
			</div>
	} catch(error) { 
		console.log("Sem conexão com o backend") 
	}
    return <div style={{width:"90vw",height:"90vh"}}>
    	<div className="row" style={{position:"absolute", left:"10vw", top:"10vh", width:"80vw",height:"80vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "80vh", fontSize: "xx-large"}}>
    		Nenhuma pesquisa foi selecionada.
    	</div>
	</div>

}

export function loadMap(idBot, title, ref) {
	var code = undefined;
	try {
		var oReq = new XMLHttpRequest();
		oReq.open("GET", backend().path + backend().port + "/map?" + idBot, false);
		oReq.send();
		var data = JSON.parse(oReq.responseText);
		if (data.length > 0)
		    return <div style={{width:"60vw",height:"72vh"}}>
		    	<div className="row" style={{width:"60vw",height:"60vh"}}>
					<GeoHeatmap addressPoints={data} title={title}/>
				</div>
			</div>
	} catch(error) { 
		console.log("Sem conexão com o backend") 
		if (error.message.startsWith("Failed to execute 'send' on 'XMLHttpRequest': Failed to load"))
		    return <div style={{width:"60vw",height:"60vh"}}>
		    	<div className="row" style={{width:"60vw",height:"60vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "60vh", fontSize: "xx-large"}}>
		    		Servidor do backend está fora do ar.
		    	</div>
			</div>
		else
		    return <div style={{width:"60vw",height:"60vh"}}>
		    	<div className="row" style={{width:"60vw",height:"60vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "60vh", fontSize: "xx-large"}}>
		    		Algo deu ruim.
		    	</div>
			</div>
	}
    return <div style={{width:"60vw",height:"60vh"}}>
    	<div className="row" style={{width:"60vw",height:"60vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "60vh", fontSize: "xx-large"}}>
    		Nenhuma pesquisa foi selecionada.
    	</div>
	</div>
}

export default Map;