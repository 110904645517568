var _IDX;

export function loadCode(value) {
	return PROGRAM(value);
}

/** Registers: Estruturas de decisão sem loop-list **/
	function PROGRAM(value) {
		_IDX = 0;
		var lines = [], columns = [], lcur = 1, ccur = 1;
		var commentLine = -1, commentBlock = -1, insideString = false;
		var comments = [];
		for (var i = 0; i < value.length; i++) {
		  if (value.charAt(i) == '"') {
		  	if (!insideString || value.charAt(i-1) != "\\")
				insideString = !insideString;
		  }
		  if (value.charAt(i) == '\n') {
		  	ccur = 0;
		  	lcur ++;
		  	if (commentLine >= 0) {
		  		comments.push([commentLine, i])
		  		commentLine = -1;
		  	}
		  } else if (i < value.length - 1) {
		  	if (!insideString) {
		  		if (commentLine == -1 && value.charAt(i) == '/' && value.charAt(i+1) == '/') {
		  			commentLine = i;
			  	} else if (commentLine == -1 && commentBlock == -1 && value.charAt(i) == '/' && value.charAt(i+1) == '*') {
			  		commentBlock = i;
			  	} else if (i > 0 && commentBlock != -1 && value.charAt(i-1) == '*' && value.charAt(i) == '/') {
			  		comments.push([commentBlock, i+1])
			  		if (commentBlock == -1) {
			  			error("Você está fechando um bloco de comentário (*/) sem tê-lo aberto!")
			  		}
			  		commentBlock = -1;
			  	}
		  	}
		  }
		  lines.push(lcur);
		  columns.push(ccur);
		  ccur++;
		}
		for (let i in comments) 
			value = value.substring(0, comments[i][0]) + " ".repeat(comments[i][1]-comments[i][0]) + value.substring(comments[i][1]);
		var id_bot, declares_list, declares, declares_id, obj, nodes, head = 0;
		head = consume(value, lines, columns, head, "bot");
		head = consume(value, lines, columns, head, "[");
		[head, id_bot] = ID_BOT(value, lines, columns, head);
		head = consume(value, lines, columns, head, "]");
		head = consume(value, lines, columns, head, "{");
		[head, declares_list] = DECLARES_LIST(value, lines, columns, head);
		[declares, declares_id] = getChains(declares_list);
		var idx = 1, operates = [];
		for (let i in declares_list) {
			if (!declares_list[i].logic) {
				for (let j in declares_list[i].body) {
					var op = declares_list[i].body[j]
					op.index = ("" + declares_list[i].title).trim();
				}
			}
		}
		for (let i in declares_list) {
			if (declares_list[i].logic) {
				var current_logic = declares_list[i].title.trim();
				operates.push({
					id: "_BEGIN_SESSION_" + current_logic,
					_id: "_BEGIN_SESSION_" + current_logic,
					say: ""
				});
				for (let j in declares_list[i].body) {
					var logic = declares_list[i].body[j];
					[idx, obj] = normalizeNode(logic, idx, declares, declares_id, current_logic);
					var clone_value = obj;
					[idx, nodes] = BODY_ELEM(idx, clone_value, clone_value);
					operates.push(nodes);
				}
				operates.push({
					id: "_END_SESSION_" + current_logic,
					_id: "_END_SESSION_" + current_logic,
					say: ""
				});
			}
		}
		operates = linkNodes(operates);
		var lastIndex = "";
		for (let i in operates) {
			if (operates[i].index == undefined)
				operates[i].index = lastIndex;
			else
				lastIndex = operates[i].index;
		}
		var code = {};
		code.title = id_bot;
		code.route = operates;
		head = consume(value, lines, columns, head, "}");
		return code;
	}

	function linkNodes(nodes) {
		var chain = [];
		const the_end = {
			id: "_THE_END_",
			_id: "_THE_END_",
			say: ""
		};
		nodes.push(the_end);
		for (let i = 0; i < nodes.length-1; i++) {
			if (!Array.isArray(nodes[i])) {
				if (nodes[i].goto == undefined) {
					if (!Array.isArray(nodes[i+1]))
						nodes[i].goto = nodes[i+1]._id;
					else
						nodes[i].goto = nodes[i+1][0]._id;
				} else if (Array.isArray(nodes[i].goto)) {
					if (!Array.isArray(nodes[i+1])) {
						if (nodes[i].goto[nodes[i].goto.length-1]["true"] == undefined)
							nodes[i].goto.push({"true": nodes[i+1]._id})
					}
					else {
						if (nodes[i].goto[nodes[i].goto.length-1]["true"] == undefined)
							nodes[i].goto.push({"true": nodes[i+1][0]._id})
					}
				}
				chain.push(nodes[i])
			} else {
				for (let j = 0; j < nodes[i].length; j++) {
					if (nodes[i][j].goto == undefined) {
						if (!Array.isArray(nodes[i+1]))
							nodes[i][j].goto = nodes[i+1]._id;
						else
							nodes[i][j].goto = nodes[i+1][0]._id;
					} else if (Array.isArray(nodes[i][j].goto)) {
						if (!Array.isArray(nodes[i+1])) {
							if (nodes[i][j].goto[nodes[i][j].goto.length-1]["true"] == undefined)
								nodes[i][j].goto.push({"true": nodes[i+1]._id})
						}
						else {
							if (nodes[i][j].goto[nodes[i][j].goto.length-1]["true"] == undefined)
								nodes[i][j].goto.push({"true": nodes[i+1][0]._id})
						}
					}
					chain.push(nodes[i][j])
				}
			}
		}
		chain.push(the_end);
		var map_links = {};
		for (let i in chain) {
			if (!map_links[chain[i].id])
				map_links[chain[i].id] = [];
			map_links[chain[i].id].unshift(chain[i]);
		}
		var relink_nodes = chain.filter(e=>typeof(e.goto) == "string");
		for (let i in relink_nodes) {
			// FIXME: Por ora, assumimos que os updates voltam para o primeiro ponto que foi perguntado.
			// 		  O ideal seria o último atravessado
			if (!map_links[relink_nodes[i].goto])
				error("A variável '" + relink_nodes[i].goto + "' não foi encontrada no contexto em que foi invocada a atualização.");
			var cand = map_links[relink_nodes[i].goto].find(e=>e._id < relink_nodes[i]._id);
			if (!cand)
				cand = map_links[relink_nodes[i].goto][0];
			relink_nodes[i].goto = cand._id;
		}
		return chain;
	}

	function normalizeKeys(elem) {
		var trans = {};
		var keys = Object.keys(elem);
		for (let i in keys) {
			var key = keys[i];
			var val = elem[key];
			key = translateAction(key);
			trans[key] = val;
		}
		return trans;
	}

	/*
		@BODY_ELEM 				-> @MTH | @IF_CLAUSE
		@MTH 					-> { "id": "...", ... }
		@IF_CLAUSE 				-> [ @IFELSEIF_CLAUSE_REC ] | [ @IFELSEIF_CLAUSE_REC @ELSE_CLAUSE]
		@IFELSEIF_CLAUSE_REC  	-> @IFELSEIF | @IFELSEIF, @IFELSEIF_CLAUSE_REC
		@IFELSEIF 				-> { "??? != true": @BODY }
		@ELSE_CLAUSE 			-> { "true": @WT }
		@WT 					-> @MTH | @BODY
		@BODY 					-> [ @BODY_LIST ]
		@BODY_LIST 				-> @BODY_ELEM | @BODY_ELEM, @BODY_LIST

		:::OR COMPACTT VERSION:::

		@BODY_ELEM 				-> @MTH | @IF_CLAUSE
		@MTH 					-> { "id": "...", ... }
		@IF_CLAUSE 				-> [ @IFELSEIF_CLAUSE_REC ]
		@IFELSEIF_CLAUSE_REC  	-> @IFELSEIF_CLAUSE | @IFELSEIF_CLAUSE, @IFELSEIF_CLAUSE_REC
		@IFELSEIF_CLAUSE 		-> { "???": @WT }
		@WT 					-> @MTH | @BODY
		@BODY 					-> [ @BODY_LIST ]
		@BODY_LIST 				-> @BODY_ELEM | @BODY_ELEM, @BODY_LIST
	*/

	function BODY_ELEM(idx, value, key) {
		if (!Array.isArray(value))
			return MTH(idx, value, key);
		return IF_CLAUSE(idx, value);
	}

	function IF_CLAUSE(idx, value) {
		if (value.length == 0)
			error("Era esperado que todo IF tivesse um corpo de operação")
		var nodes = [], ifcases, root = {
			id: "IF " + (idx++),
			_id: _IDX++,
			say: "",
			goto: []
		};
		nodes.push(root);
		for (let i in value) {
			[idx, ifcases] = IFELSEIF_CLAUSE(idx, value[i], root);
			nodes.push.apply(nodes, ifcases);
		}
		return [idx, nodes];
	}

	function IFELSEIF_CLAUSE(idx, value, root) {
		var key = Object.keys(value)[0];
		var ifcases, val = value[key];
		[idx, ifcases] = WT(idx, val, key);
		root.goto.push({
			[key]: ifcases[0]._id
		});
		return [idx, ifcases];
	}

	function MTH(idx, value, key) {
		if (value != undefined && value.id != undefined) 
			return [idx, [value]];
		else if (key != undefined)
			error("Erro de semântica: o método '" + key + "' ainda não está disponível para ser usado como elemento operativo. Talvez ele seja útil em comparações.")
		else {
			error("Erro de semântica: ocorreu um erro inesperado no nó '" + JSON.stringify(value) + "' ao tentar usá-lo como método operativo não autorizado. Só é permitido o uso dos seguintes métodos: 'atualize', 'pergunte', 'invoque', 'morra', 'retorne' e 'recebe'.")
		}
	}

	function WT(idx, value, key) {
		if (!Array.isArray(value)) 
			return MTH(idx, value, key);
		return BODY(idx, value);
	}

	function planify(nodes) {
		var nodes_plane = [];
		for (let i = 0; i < nodes.length; i++) {
			if (i < nodes.length - 1) {
				var nextKey = null;
				if (Array.isArray(nodes[i+1])) {
					nextKey = nodes[i+1][0]._id;
				} else {
					nextKey = nodes[i+1]._id;
				}
				if (Array.isArray(nodes[i])) {
					for (let j in nodes[i])
						if (nodes[i][j].goto == undefined)
							nodes[i][j].goto = nextKey;
						else if (Array.isArray(nodes[i][j].goto)) {
							var gt = nodes[i][j].goto;
							if (Object.keys(gt[gt.length-1]) != "true")
								nodes[i][j].goto.push({"true": nextKey})
						}
				} else {
					if (nodes[i].goto == undefined)
						nodes[i].goto = nextKey;
					else if (Array.isArray(nodes[i].goto)) {
						var gt = nodes[i].goto;
						if (Object.keys(gt[gt.length-1]) != "true")
							nodes[i].goto.push({"true": nextKey})
					}
				}
			}
			if (Array.isArray(nodes[i])) 
				nodes_plane.push.apply(nodes_plane, nodes[i]);
			else
				nodes_plane.push(nodes[i]);
		}
		return nodes_plane;
	}

	function BODY(idx, value) {
		var elems, nodes = [], j = 0;
		for (let i in value) {
			if (value[i] != undefined) {
				[idx, elems] = BODY_ELEM(idx, value[i], value[i].id);
				nodes.push(elems);
			} 
		}
		return [idx, planify(nodes)];
	}

	function expandMethods(logic, idx, chain, chain_id, current_logic) {
		if (!logic.strval.includes("@"))
			error("Erro de semântica: a função '" + logic.strval + "' foi invocada em um contexto inapropriado.");
		const pieces = logic.strval.split("@");
		var key = pieces[0];
		var val = pieces[1];
		var mth = {}, aux;
		if (key =="ASK") {
			mth = JSON.parse(JSON.stringify(chain[chain_id[val]]));
			mth._id = _IDX++;
			if (mth == undefined)
				error("Erro de semântica: a questão '" + val + "' precedida do método \"pergunte\" não foi declarada.");
		} else if (key == "UPDATE") {
			const nd_idx = val.substring(1,val.length-1);
			mth = {
				id: "UPDATE " + (idx++),
				_id: _IDX++,
				say: "",
				goto: nd_idx
			}
		} else if (key == "ATTRIB") {
			var args = val.split("|");
			mth = {
				id: "ATTRIB " + (idx++),
				_id: _IDX++,
				operand: args[0].substring(1,args[0].length-1),
				value: args[1]
			}
		} else if (key == "CALL") {
			val = val.trim();
			if (val.startsWith("#"))
				val = val.substring(1);
			mth = {
				id: "CALL " + (idx++),
				_id: _IDX++,
				say: "",
				goto: "_BEGIN_SESSION_" + val.trim()
			}
		} else if (key == "RETURN") {
			mth = {
				id: "RETURN " + (idx++),
				_id: _IDX++,
				say: "",
				goto: "_END_SESSION_" + current_logic
			}
		} else if (key == "DIE") {
			mth = {
				id: "DIE " + (idx++),
				_id: _IDX++,
				say: "",
				goto: "_THE_END_"
			}
		} else
			error("Erro de semântica: o comando '" + logic.strval + "' invocado não foi reconhecido.");
		return [idx, mth];
	}

	function normalizeNode(logic, idx, chain, chain_id, current_logic) {
		var obj = [];
		if (logic.strval != undefined) 
			return expandMethods(logic, idx, chain, chain_id, current_logic);
		if (logic.operations == undefined) {
			logic.id = "Logic " + (idx++);
			logic._id = _IDX++;
			return [idx, normalizeKeys(logic)];
		}
		var goto = [];
		var boolexp = getBooleanExpression(logic.operations);
		var ret, action_nested = [];
		for (let k in logic.actions) {
			[idx, ret] = normalizeNode(logic.actions[k], idx, chain, chain_id, current_logic);
			action_nested.push(ret)
		}
		goto.push({[boolexp] : action_nested})
		var esle = logic.esle;
		// os do while são alternativos
		while (esle) {
			// os do for são consecutivos
			if (esle.operations != undefined) {
				var action_nested = [];
				for (let k in esle.actions) {
					[idx, ret] = normalizeNode(esle.actions[k], idx, chain, chain_id, current_logic);
					action_nested.push(ret)
				}
				goto.push({[getBooleanExpression(esle.operations)] : action_nested})
			} else {
				if (Array.isArray(esle)) {
					var action_nested = [];
					for (let k in esle) {
						[idx, ret] = normalizeNode(esle[k], idx, chain, chain_id, current_logic);
						action_nested.push(ret)
					}
					goto.push({"true" : action_nested})
				} else {
					[idx, ret] = normalizeNode(esle, idx, chain, chain_id, current_logic);
					goto.push({"true" : ret})
				}
			}
			esle = esle.esle
		}
		return [idx, goto];
	}

	function CHAPTER(value, lines, columns, head) {
		var id_chapter, question_list;
		head = consume(value, lines, columns, head, H_CHAPTER());
		[head, id_chapter] = ID_CHAPTER(value, lines, columns, head);
		head = consume(value, lines, columns, head, "{");
		[head, question_list] = QUESTION_LIST(value, lines, columns, head);
		head = consume(value, lines, columns, head, "}");
		var chapter = {};
		chapter.title = id_chapter;
		chapter.body = question_list;
		return [head, chapter];
	}

	function H_CHAPTER() {
		return "@";
	}

	function SINGLE_PROPERTY(value, lines, columns, head) {
		var key_prop, val_prop;
		[head, key_prop] = ID_S_PROPERTY(value, lines, columns, head);
		head = consume(value, lines, columns, head, ":");
		[head, val_prop] = STRING(value, lines, columns, head);
		var prop = {};
		prop[key_prop] = val_prop;
		return [head, prop];
	}

	function QUESTION(value, lines, columns, head) {
		var id_question, prop_list;
		head = consume(value, lines, columns, head, "[");
		[head, id_question] = ID_QUESTION(value, lines, columns, head);
		head = consume(value, lines, columns, head, "]");
		head = consume(value, lines, columns, head, "{");
		[head, prop_list] = PROPERTY_LIST(value, lines, columns, head);				
		head = consume(value, lines, columns, head, "}");
		var questions = {};
		questions.id = id_question.trim();
		for (var i in prop_list) {
			var key = Object.keys(prop_list[i])[0];
			questions[translateKey(key)] = Object.values(prop_list[i])[0]
		}
		return [head, questions];
	} 	

	function ACTION(value, lines, columns, head) {
		return SINGLE_ACTION(value, lines, columns, head);
	}

	function SINGLE_ACTION(value, lines, columns, head) {
		var key_action, val_action;
		[head, key_action] = ID_S_ACTION(value, lines, columns, head);
		head = consume(value, lines, columns, head, ":");
		[head, val_action] = VALUE(value, lines, columns, head);
		var act = {};
		act[key_action] = val_action;
		return [head, act];
	}	

	function OPERATION(value, lines, columns, head) {
		head = advanceClear(value, head);
		if (ahead(value, head, H_METH())) {
			return METHOD(value, lines, columns, head);
		} else {
			return FUNCT(value, lines, columns, head);
		}
	} 	

	function METHOD(value, lines, columns, head) {
		var id_question;
		head = consume(value, lines, columns, head, "[");
		[head, id_question] = ID_QUESTION(value, lines, columns, head);
		head = consume(value, lines, columns, head, "]");
		head = advanceClear(value, head);
		var tail = null;
		if (ahead(value, head, ".")) {
			[head, tail] = TAIL_LIST(value, lines, columns, head);
		}
		var method = {};
		method.strval = translateFun(id_question, tail);
		return [head, method];
	}

	function TAIL(value, lines, columns, head) {
		var id_method, args_list;
		head = consume(value, lines, columns, head, ".");
		[head, id_method] = ID_METHOD(value, lines, columns, head);
		head = consume(value, lines, columns, head, "(");
		[head, args_list] = ARGS_LIST(value, lines, columns, head);
		head = consume(value, lines, columns, head, ")");
		var tail = {};
		tail.method = id_method;
		tail.args = args_list;
		return [head, tail];
	}

	function FUNCT(value, lines, columns, head) {
		var id_fun, args_list;
		[head, id_fun] = ID_FUNCT(value, lines, columns, head);
		head = consume(value, lines, columns, head, "(");
		[head, args_list] = ARGS_LIST(value, lines, columns, head);
		head = consume(value, lines, columns, head, ")");
		head = advanceClear(value, head);
		var tail = [];
		if (ahead(value, head, ".")) {
			[head, tail] = TAIL_LIST(value, lines, columns, head);
		}
		tail.unshift({method: id_fun, args: args_list});
		var fun = {};
		fun.strval = translateFun(null, tail);
		return [head, fun];
	}

	function COMPOSED_PROPERTY(value, lines, columns, head) {
		var key_comp, val_comp;
		[head, key_comp] = ID_C_PROPERTY(value, lines, columns, head);
		head = consume(value, lines, columns, head, ":");
		[head, val_comp] = STATIC_VECTOR(value, lines, columns, head)
		var act = {};
		act[key_comp] = val_comp;
		return [head, act];
	} 	

	function STATIC_VECTOR(value, lines, columns, head) {
		var val_comp = [];
		head = consume(value, lines, columns, head, "{");
		[head, val_comp] = STRING_LIST(value, lines, columns, head);
		head = consume(value, lines, columns, head, "}");
		return [head, val_comp];
	}

	function LOGIC(value, lines, columns, head) {
		var id_logic, question_list;
		head = consume(value, lines, columns, head, H_LOGIC());
		[head, id_logic] = ID_LOGIC(value, lines, columns, head);
		head = consume(value, lines, columns, head, "{");
		[head, question_list] = EXP_LIST(value, lines, columns, head);
		head = consume(value, lines, columns, head, "}");
		var logic = {};
		logic.title = id_logic;
		logic.body = question_list;
		return [head, logic];
	}
	
	function H_LOGIC() {
		return "#";
	}

	function EXPRESSION(value, lines, columns, head) {
		var action, op_list, act_list, expr = {};
		head = advanceClear(value, head);
		if (ahead(value, head, H_DO())) {
			head = advanceClear(value, head);
			head = consume(value, lines, columns, head, H_DO());
			head = consume(value, lines, columns, head, "{");
			[head, action] = BD_EXPR(value, lines, columns, head);
			head = consume(value, lines, columns, head, "}");
			return [head, normalizeKeys(action)];
		}
		if (ahead(value, head, H_IF())) {
			head = consume(value, lines, columns, head, H_IF());
			[head, op_list] = OPERATION_LIST(value, lines, columns, head);
			expr.operations = op_list;
			head = consume(value, lines, columns, head, "{");
			[head, act_list] = BD_EXPR(value, lines, columns, head);
			head = consume(value, lines, columns, head, "}");
			var actions = [];
			if (!Array.isArray(act_list))
				actions = [normalizeKeys(act_list)];
			else for (let i in act_list) {
				if (act_list[i].operations || act_list[i].strval != undefined)
					actions.push(act_list[i])
				else 
					actions.push(normalizeKeys(act_list[i]));
			}
			expr.actions = actions;
			var esle = null;
			head = advanceClear(value, head);
			if (ahead(value, head, H_ELSE()))
				[head, esle] = ELSE_OPT(value, lines, columns, head);
			expr.esle = esle;
			return [head, expr];
		}
		return OPERATION(value, lines, columns, head);
	}

	function H_IF() {
		return "SE";
	}

	function H_DO() {
		return "FAÇA";
	}

	function H_METH() {
		return "[";
	}

	function H_FUNCT() {
		return ["morra", "invoque", "retorne"];
	}

/** Selectors: Estruturas de decisão sem loop-list **/
	function PROPERTY(value, lines, columns, head) {
		head = advanceClear(value, head);
		if (multiAhead(value, head, H_ID_C_PROPERTY()))
			return COMPOSED_PROPERTY(value, lines, columns, head);
		return SINGLE_PROPERTY(value, lines, columns, head);
	} 	

	function ELSE_OPT(value, lines, columns, head) {
		var action;
		head = consume(value, lines, columns, head, H_ELSE());
		head = advanceClear(value, head);
		if (ahead(value, head, "{")) {
			head = consume(value, lines, columns, head, "{");
			[head, action] = BD_EXPR(value, lines, columns, head);
			head = consume(value, lines, columns, head, "}");
			return [head, action];
		}
		return EXPRESSION(value, lines, columns, head);
	}	

	function H_ELSE() {
		return "SENÃO"
	}

/** Lists: itera e monta as estruturas de lista **/
	function DECLARES_LIST(value, lines, columns, head) {
		var chapter, o_list, logic, list = [];
		head = advanceClear(value, head);
		if (ahead(value, head, H_CHAPTER())) {
			[head, chapter] = CHAPTER(value, lines, columns, head);
			list.push(chapter);
			[head, o_list] = DECLARES_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		} else if (ahead(value, head, H_LOGIC())) {
			[head, logic] = LOGIC(value, lines, columns, head);
			list.push(logic);
			logic.logic = true;
			[head, o_list] = DECLARES_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list];
	} 

	function ARGS_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		head = advanceClear(value, head);
		if (ahead(value, head, ")")) {
			return [head, list]
		}
		[head, val] = VALUE(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (ahead(value, head, ",")) {
			head = consume(value, lines, columns, head, ",");
			[head, o_list] = ARGS_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	}	

	function IS_EXP(value, head) {
		return ahead(value, head, H_METH()) || ahead(value, head, H_IF()) || ahead(value, head, H_DO()) || multiAhead(value, head, H_FUNCT())
	}

	function BD_EXPR(value, lines, columns, head) {
		var list;
		head = advanceClear(value, head);
		if (IS_EXP(value, head))
			return EXP_LIST(value, lines, columns, head);
		[head, list] = ACTION_LIST(value, lines, columns, head);
		var act = {};
		for (let i in list) {
			var key = Object.keys(list[i])[0];
			act[key] = list[i][key];
		}
		return [head, act];
	}

	function TAIL_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		[head, val] = TAIL(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (multiAhead(value, head, ".")) {
			[head, o_list] = TAIL_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	}


	function ACTION_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		[head, val] = ACTION(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (multiAhead(value, head, H_ID_S_ACTION())) {
			[head, o_list] = ACTION_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	}	

	function OPERATION_LIST(value, lines, columns, head) {
		var not_op, op, o_list, left, list = [];
		head = advanceClear(value, head);
		if (ahead(value, head, H_NOT_OPERATOR())) {
			[head, not_op] = NOT_OPERATOR(value, lines, columns, head);
			list.push(not_op);
		}

		head = advanceClear(value, head);
		if (ahead(value, head, "(")) {
			head = consume(value, lines, columns, head, "(");
			[head, o_list] = OPERATION_LIST(value, lines, columns, head);
			head = consume(value, lines, columns, head, ")");
			list.push("(");
			list.push.apply(list, o_list);
			list.push(")");
		} else {
			[head, left] = OPERATION(value, lines, columns, head);
			list.push(left);
		}
		head = advanceClear(value, head);
		if (multiAhead(value, head, H_LOGIC_OPERATOR())) {
			[head, op] = LOGIC_OPERATOR(value, lines, columns, head);
			[head, o_list] = OPERATION_LIST(value, lines, columns, head);
			list.push(op);
			list.push.apply(list, o_list);
		}
		return [head, list]
	} 	

	function PROPERTY_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		[head, val] = PROPERTY(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (multiAhead(value, head, H_PROPERTY())) {
			[head, o_list] = PROPERTY_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	} 	

	function STRING_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		[head, val] = STRING(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (ahead(value, head, "\"")) {
			[head, o_list] = STRING_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	} 	

	function EXP_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		[head, val] = EXPRESSION(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (IS_EXP(value, head)) {
			[head, o_list] = EXP_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	} 	

	function QUESTION_LIST(value, lines, columns, head) {
		var val, o_list, list = [];
		[head, val] = QUESTION(value, lines, columns, head);
		list.push(val);
		head = advanceClear(value, head);
		if (ahead(value, head, H_METH())) {
			[head, o_list] = QUESTION_LIST(value, lines, columns, head);
			list.push.apply(list, o_list);
		}
		return [head, list]
	}

/** Valuers: valida o "HEAD", avança o HEAD e ou retorna ERROR ou o "valor" **/
	function VALUE(value, lines, columns, head) {
		head = advanceClear(value, head);
		if (ahead(value, head, "\""))
			return STRING(value, lines, columns, head);
		if (ahead(value, head, "{"))
			return STATIC_VECTOR(value, lines, columns, head)
		if (ahead(value, head, H_METH()))
			return METHOD(value, lines, columns, head);
		if (multiAhead(value, head, H_BOOLEAN)) 
			return BOOLEAN(value, lines, columns, head);
		if (isNaN(value[head])) 
			return FUNCT(value, lines, columns, head);
		return NUMBER(value, lines, columns, head);
	}

	function NUMBER(value, lines, columns, head) {
		head = advanceClear(value, head);
		if (head == value.length)
			error("O programa finalizou inesperadamente. Era esperado pelo menos um número antes de finalizar");
		var val = "", firstHead = head; 
		while (head < value.length && !isNaN(value.substring(firstHead, head+1))) {
			val += "" + value[head];
			head ++;
		}
		return [head, new Number(val)];
	}

	function BOOLEAN(value, lines, columns, head) {
		head = advanceClear(value, head);
		if (ahead(value, head, "true")) {
			head = consume(value, lines, columns, head, "true");
			return [head, true];
		}
		head = consume(value, lines, columns, head, "false");
		return [head, false];
	}

	function H_BOOLEAN() {
		return ["true", "false"];
	}

	function STRING(value, lines, columns, head) {
		head = advanceClear(value, head);
		if (head == value.length)
			error("O programa finalizou inesperadamente. Era esperado pelo menos uma String antes de finalizar");
		head = consume(value, lines, columns, head, "\"");
		var val = ""; var lastEscape = false;
		while (head < value.length && (value[head] != "\"" || lastEscape)) {
			lastEscape = false;
			val += value[head];
			if (!lastEscape && val == "\\")
				lastEscape = true;
			head ++;
		}
		head = consume(value, lines, columns, head, "\"");
		if (head == value.length)
			error("O programa finalizou inesperadamente. Era esperado pelo menos uma String antes de finalizar");
		return [head, val];
	}	

/** Consumers: valida o "HEAD", avança o HEAD e ou retorna ERROR ou o "valor" **/
	function ID_S_ACTION(value, lines, columns, head) {
		return multiMatch(value, lines, columns, advanceClear(value, head), H_ID_S_ACTION());
	}

	function H_ID_S_ACTION() {
		return ['diga', 'reporte', 'status', 'referência', 'explicação', 'recomendação', 'relevância',  "configuração", "título", "identificador", "estrelas[1]", "estrelas[2]", "estrelas[3]", "estrelas[4]", "estrelas[5]", "estrelas[6]", "estrelas[7]", "tipo", "raio", "cálculo", "cor[baixa]", "cor[média]", "cor[alta]", "quantização", "vínculo"];
	}

	function ID_S_PROPERTY(value, lines, columns, head) {
		/*
			--> RESTRICTIONS: 
			tipo: "Texto"
			tipo: "Binário"
			tipo: "Data"
			tipo: "Horário"
			tipo: "Número"
				subtipo:
					"Inteiro"
					"Real"
					"Monetário"
			tipo: "Lista"
				subtipo:
					"Atômica"
					"Múltipla"
		*/
		return multiMatch(value, lines, columns, advanceClear(value, head), H_ID_S_PROPERTY());
	} 	

	function H_ID_S_PROPERTY() {
		return['pergunta', 'ajuda', 'tipo', 'subtipo'];
	}

	function ID_C_PROPERTY(value, lines, columns, head) {
		return multiMatch(value, lines, columns, advanceClear(value, head), H_ID_C_PROPERTY());
	}

	function H_ID_C_PROPERTY() {
		return ['opções'];
	}

	function H_PROPERTY() {
		return ['opções', 'pergunta', 'ajuda', 'tipo', 'subtipo'];
	}

	function NOT_OPERATOR(value, lines, columns, head) {
		return [consume(value, lines, columns, head, H_NOT_OPERATOR()), "!"];
	}

	function H_NOT_OPERATOR() {
		return 'NÃO';
	}

	function LOGIC_OPERATOR(value, lines, columns, head) {
		return multiMatch(value, lines, columns, advanceClear(value, head), H_LOGIC_OPERATOR());
	}

	function H_LOGIC_OPERATOR() {
		return ['E', 'AND', 'OU', 'OR'];
	}

/** Breakers: valida o "HEAD", avança o HEAD até um Char e o "valor" **/
	function ID_BOT(value, lines, columns, head) {
		return advanceUntil(value, head, "]");
	}	

	function ID_QUESTION(value, lines, columns, head) {
		// --> RESTRICTIONS ID_QUESTION já declarado!
		return advanceUntil(value, head, "]");
	}	

	function ID_CHAPTER(value, lines, columns, head) {
		return advanceUntil(value, head, "{");
	}	

	function ID_LOGIC(value, lines, columns, head) {
		return advanceUntil(value, head, "{");
	}

	function ID_METHOD(value, lines, columns, head) {
		// --> RESTRICTIONS ID_METHOD Mapeado
		return advanceUntil(value, head, "(");
	}

	function ID_FUNCT(value, lines, columns, head) {
		// --> RESTRICTIONS ID_FUNCT Mapeado
		return advanceUntil(value, head, "(");
	} 	

/** UTILS **/
	function advanceUntil(value, head, pattern) {
		head = advanceClear(value, head);
		if (head == value.length)
			error("O programa finalizou inesperadamente. Era esperado pelo menos o token '" + pattern + "' antes de finalizar");
		var val = "";
		while (head < value.length && value[head] != pattern) {
			val += value[head];
			head ++;
		}
		if (head == value.length) {
			if (val == "")
				error("O programa finalizou inesperadamente. Era esperado pelo menos o token '" + pattern + "' antes de finalizar");
			else
				error("Erro de semântica: a sequência de caracteres a partir de '" + val.substring(0, val.length > 30 ? 30 : val.length) + "' é inválida");
		}
		return [head, val];
	}

	function consume(value, lines, columns, head, pattern) {
		head = advanceClear(value, head);
		if (head == value.length)
			error("O programa finalizou inesperadamente. Era esperado pelo menos o token '" + pattern + "' antes de finalizar");
		if (match(value, head, pattern))
			return head + pattern.length;
		error("Erro de sintaxe na linha " + lines[head] + ", coluna " + columns[head] + ". Era esperado '" + pattern + "' mas veio '" + value.substring(head, Math.min(head + pattern.length, value.length)) + "'");
	}

	function match(value, head, pattern) {
		return value.substring(head, Math.min(head + pattern.length, value.length))	== pattern;
	}

	function multiAhead(value, head, patterns) {
		for (let i in patterns) {
			if (match(value, head, patterns[i]))
				return true;
		}
		return false;				
	}

	function ahead(value, head, pattern) {
		if (match(value, head, pattern))
			return true;
		return false;				
	}

	function error(msg) {
		//alert(msg);
		throw new Error(msg);
	}

	function advanceClear(value, head) {
		while (head < value.length && (value[head] == " " || value[head] == "\n" || value[head] == "\t"))
			head ++;
		return head;
	}

	function multiMatch(value, lines, columns, head, opts) {
		for (let i in opts) {
			if (match(value, head, opts[i])) 
				return [head + opts[i].length, opts[i]]
		}
		var veio = value.substring(head, head + 30) + "..."
		error("Era esperado um dos seguintes elementos na linha " + lines[head] + ", coluna " + columns[head] + ": " + opts + ". No entanto, veio '" + veio + "'");
	}

	function translateOp(op) {
		if (op == "E" || op == "AND")
			op = " && ";
		else if (op == "OU" || op == "OR")
			op = " || ";
		return op;
	}

	function translateKey(key) {
		if (key == "pergunta")
			key = "ask";
		else if (key == "ajuda")
			key = "help";
		else if (key == "tipo")
			key = "type";
		else if (key == "subtipo")
			key = "subtype";
		else if (key == "opções")
			key = "options";
		return key;
	}

	function translateAction(key) {
		if (key == "diga")
			key = "say";
		else if (key == "reporte")
			key = "link";
		else if (key == "status")
			key = "status";
		else if (key == "referência")
			key = "reference";
		else if (key == "explicação")
			key = "explanation";
		else if (key == "recomendação")
			key = "recommendation";
		else if (key == "relevância")
			key = "relevance";
		else if (key == "configuração")
			key = "config";
		else if (key == "título")
			key = "title";
		else if (key == "identificador")
			key = "identifier";
		else if (key == "estrelas[1]")
			key = "star1";
		else if (key == "estrelas[2]")
			key = "star2";
		else if (key == "estrelas[3]")
			key = "star3";
		else if (key == "estrelas[4]")
			key = "star4";
		else if (key == "estrelas[5]")
			key = "star5";
		else if (key == "estrelas[6]")
			key = "star6";
		else if (key == "estrelas[7]")
			key = "star7";
		else if (key == "tipo")
			key = "type";
		else if (key == "raio")
			key = "ray";
		else if (key == "cálculo")
			key = "function";
		else if (key == "cor[baixa]")
			key = "mincolor";
		else if (key == "cor[média]")
			key = "midcolor";
		else if (key == "cor[alta]")
			key = "maxcolor";
		else if (key == "quantização")
			key = "quantization";
		else if (key == "vínculo")
			key = "link";
		return key;
	}

	function translateFun(question, tail) {
		var left = "";
		if (question)
			left = "[" + question + "]";
		if (tail == null)
			return left;
		var root = funAnalyzer(left, tail[0].method, tail[0].args);
		for (let i = 1; i < tail.length; i++)
			root = funAnalyzer(root, tail[i].method, tail[i].args)
		return root;
	}

	function funAnalyzer(left, fun, args) {
		fun = fun.trim();
		 /* compile time */
		if (fun == "pergunte")
			fun = "ASK@" + left;
		else if (fun == "atualize")
			fun = "UPDATE@" + left;
		else if (fun == "invoque")
			fun = "CALL@" + args[0];
		else if (fun == "retorne")
			fun = "RETURN@";
		else if (fun == "morra")
			fun = "DIE@";
		else if (fun == "recebe")
			fun = "ATTRIB@" + left + "|" + args[0];
		/* execution time */
		else if (fun == "verdadeiro")
			fun = "isTrue(" + left + ")";
		else if (fun == "falso")
			fun = "isFalse(" + left + ")";
		else if (fun == "idêntico")
			fun = "identical(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "igual")
			fun = "equals(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "diferente")
			fun = "notEquals(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "mais")
			fun = "plus(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "menos")
			fun = "minus(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "multiplicado")
			fun = "mult(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "dividido")
			fun = "div(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "maior")
			fun = "greater(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "menor")
			fun = "less(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "maiorIgual")
			fun = "greaterEquals(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "menorIgual")
			fun = "lessEquals(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "maiúscula")
			fun = "uppercase(" + left + ")";
		else if (fun == "minúscula")
			fun = "lowercase(" + left + ")";
		else if (fun == "contém")
			fun = "contains(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "total")
			fun = "total(" + left + ")";
		else if (fun == "contagem")
			fun = "count(" + left + ")";
		else if (fun == "intersecção" || fun == "interseção")
			fun = "intersect(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "união")
			fun = "union(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "diferençaConjunto")
			fun = "diffSet(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "antes")
			fun = "before(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "depois")
			fun = "after(" + left + "," + process(left, args[0]) + ")";
		else if (fun == "até")
			fun = "until(" + left + "," + process(left, args[0]) + ", " + process(left, args[1]) + ")";
		else if (fun == "desde")
			fun = "since(" + left + "," + process(left, args[0]) + ", " + process(left, args[1]) + ")";
		else if (fun == "respondido")
			fun = "answered(" + left + ")";
		else if (fun == "hoje")
			fun = "today()";
		else if (fun == "agora")
			fun = "now()";
		else
			error("Erro de semântica: comando não reconhecido: '" + fun + "'");
		return fun;
	}

	function process(left, arg) {
		if (typeof(arg) == "string")
			return "\"" + arg + "\""
		if (Array.isArray(arg)) {
			if (arg.length == 0)
				return "[]";
			var ret = "[\"" + arg[0] + "\"";
			for (let i = 1; i < arg.length; i++)
				ret += ",\"" + arg[i] + "\"";
			ret += "]";
			return ret;
		}
		if (typeof(arg) == "object" && arg.id)
			return funAnalyzer(left, arg.id, arg.args)
		if (typeof(arg) == "object" && arg.strval)
			return "(" + arg.strval + ")" 
		return arg;
	}

	function getIdBrackets(val) {
		var matches = val.match(/\[(.*?)\]/g);
		var set = {};
		if (matches) {
			for (let i in matches) {
				var submatch = matches[i];
				set[submatch] = true;
			}
		}
		return Object.keys(set);
	}

	function isEmpty(obj) {
	    for(var key in obj) {
	        if(obj.hasOwnProperty(key))
	            return false;
	    }
	    return true;
	}

	function getChains(declares_list) {
		var chain = [], chain_id = {};
		for (let i in declares_list)
			if (!declares_list[i].logic) {
				for (let j in declares_list[i].body)
					chain.push(declares_list[i].body[j])
			}
		for (var i = 0; i < chain.length; i++) 
			chain_id["[" + chain[i].id + "]"] = i;
		return [chain, chain_id];
	}

	function getBooleanExpression(operations) {
		var boolexp = "";
		for (let i in operations)
			if (operations[i].strval != undefined)
				boolexp += operations[i].strval;
			else
				boolexp += translateOp(operations[i]);
		return boolexp;
	}
