import React, { Component } from 'react';
import logo from "./img/inicioquiz.png";
//import './src/styles/app.css';
import { backend } from  './config.js';

class Quiz extends Component {
  	constructor(props) {
	    super(props);
	    this.state = { 
	    	program: null 
	    };
	}

	async componentDidMount() {
		var data = null;
		let id = "competition";
		if (window.location.search.startsWith("?id="))
			id = window.location.search.substring(4);
		else if(sessionStorage.getItem("id") !== null)
		    id = sessionStorage.getItem("id");
		sessionStorage.setItem("id", id);
		var oReq = new XMLHttpRequest();
		oReq.open("POST", backend() + "/get?id=" + id, false);
		oReq.send();
		var data = JSON.parse(oReq.responseText);
      	sessionStorage.setItem("program", JSON.stringify(data));
	    this.setState({ program: data });
	}

	render() {
		if (!this.state.program)
			return null;
        const program = this.state.program;
        var title = "Bem vindo ao LiberQuiz";
        var subtitle = "";
        if (program && program.options) {
          if (program.options.title) {
            title = program.options.title;
          }
          if (program.options.description) {
            subtitle = program.options.description;
          }
          var hsStyle = "none";
          if (program.options.ranking && !program.options.multiples) {
          	hsStyle = "block";
          }
        }
		return <table>
			<tbody>
				<tr>
					<td className="cell-body">
				    	<div className="container">
				    		<div key="home" className="flex-center flex-column">
				    		    <a><img src={logo} width="200" height="200"/></a>
			      				<center><h2 key="title" style={{marginTop:30, fontSize: "2.75rem"}} dangerouslySetInnerHTML={{ __html: title }}></h2></center>
				          		<center><h3 key="subtitle" style={{position: "relative", top:-25, fontSize: "2rem", textAlign:"justify"}}
				          			dangerouslySetInnerHTML={{ __html: subtitle }}></h3></center>
				    			<a className= "btn" href="/quiz/game"> Jogar </a>
				    			<a key="highscores" className= " btn " href="/quiz/highscores" style={{display: hsStyle}}> Ranking </a >
				    		</div>
				    	</div>
		    		</td>
		    	</tr>
		    </tbody>
	    </table>
	}
}

export default Quiz;