import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './App.css';

import Main from './Main.js';
import Bot from './Bot.js';
import Send from './Send.js';
import Map from './Map.js';

import Quiz from './quiz/Quiz.js';
import SendQuiz from './quiz/SendQuiz.js';
import Game from './quiz/src/pages/Game.js';
import End from './quiz/src/pages/End.js';
import Highscores from './quiz/src/pages/Highscores.js';

// ************************** CAN WAIT **************************
// * No caso de update de respostas, saltar as perguntas e comentários decorrentes do diálogo de questões já respondidas
// * Banco de inteligência do bot alimentado via json (banco de cadastros)
// * Possibilidade de ser usado como GeD
// * Link de respostas automaticamente na ausencia de "pergunte" e "valor atribuído"
// * Máscaras
// * Atribuições
// * Tipo scale
// * Objetos
// * Loop Out
// * Loop IN
// 	- Vetores primitivos 
//  - Vetores de objetos
// * Multiuser mode
// * Multicontext mode
// * Help na pergunta
// * Componentes especiais (números, datas e horários)
// * Importação de arquivos de resposta em excel de acordo com modelo fornecido
// * Background processing de apis
// * Recuperação de perfil por chave de recuperação
// * Canal aberto para comunicação precisa por canal segmentado
// * Whatsapp de bots para temas afins

// Report daora >>> https://www.npmjs.com/package/react-report-builder

function App() {
	return (<BrowserRouter>
		<Switch>
			<Route key={"root"} path="/" exact={true} component={Main}/>
			<Route key={"bot"} path="/bot" component={Bot}/>
			<Route key={"send"} path="/send" component={Send}/>
			<Route key={"map"} path="/map" component={Map}/>

			<Route key={"quiz"} path="/quiz" exact={true} component={Quiz}/>
			<Route key={"sendquiz"} path="/quiz/send" exact={true} component={SendQuiz}/>
			<Route key={"gamequiz"} path="/quiz/game" exact={true} component={Game}/>
			<Route key={"endquiz"} path="/quiz/end" exact={true} component={End}/>
			<Route key={"highscoresquiz"} path="/quiz/highscores" exact={true} component={Highscores}/>
        </Switch>
	  </BrowserRouter>);
}

export default App;
