import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Bubble from './Bubble';
import Image from './Image';
import ImageHelp from './ImageHelp';
import ImageContainer from './ImageContainer';
import Loading from '../common/Loading';
import TextStepContainer from './TextStepContainer';

class TextStep extends Component {
  /* istanbul ignore next */
  state = {
    loading: true,
    showHelp: false
  };

  componentDidMount() {
    const { step, speak, previousValue, triggerNextStep } = this.props;
    const { component, delay, waitAction } = step;
    const isComponentWatingUser = component && waitAction;

    setTimeout(() => {
      this.setState({ loading: false }, () => {
        if (!isComponentWatingUser && !step.rendered) {
          triggerNextStep();
        }
        speak(step, previousValue);
      });
    }, delay);
  }

  updateAnswer = (id) => {
    const { triggerStep } = this.props;
    triggerStep(id);
  }

  getMessage = () => {
    const { previousValue, step } = this.props;
    const { message } = step;
    var value = message ? message.replace(/{previousValue}/g, previousValue) : '';
    if (value.includes("\\n")) {
      const pieces = value.split("\\n");
      var listOm = [];
      for (let i in pieces)
        listOm.push(<div>{pieces[i]}</div>)
      return listOm;
    }
    return value;
  };

  renderMessage = () => {
    const { step, steps, previousStep, triggerNextStep } = this.props;
    var { component } = step;
    if (typeof(component) == "function")
      step.component = component();
    if (component) {
      return React.cloneElement(component, {
        step,
        steps,
        previousStep,
        triggerNextStep
      });
    }

    return this.getMessage();
  };

  render() {
    const {
      step,
      isFirst,
      isLast,
      avatarStyle,
      bubbleStyle,
      hideBotAvatar,
      hideUserAvatar
    } = this.props;
    const { loading } = this.state;
    const { avatar, user, help, _id } = step;
    const showAvatar = user ? !hideUserAvatar : !hideBotAvatar;

    if (this.renderMessage() == "") {
      return "";
    }

    return (
      <>
        <div>
          <TextStepContainer className={`rsc-ts ${user ? 'rsc-ts-user' : 'rsc-ts-bot'}`} user={user}>
            <ImageContainer className="rsc-ts-image-container" user={user}>
              {isFirst && showAvatar && (
                <Image
                  className="rsc-ts-image"
                  style={avatarStyle}
                  showAvatar={showAvatar}
                  user={user}
                  src={avatar}
                  alt="avatar"
                />)
              }
            </ImageContainer>
            <Bubble
              className="rsc-ts-bubble"
              style={bubbleStyle}
              user={user}
              showAvatar={showAvatar}
              isFirst={isFirst}
              isLast={isLast}
            >
              {loading ? <Loading /> : this.renderMessage()}
              {user && (<div onClick={()=>this.updateAnswer(_id)} style={{
                  ...bubbleStyle,
                  cursor: "pointer",
                  //background: "rgb(255 255 255)",
                  color: "#141414",
                  position: "relative",
                  zIndex: 0,
                  bottom: -16,
                  right: -7,
                  paddingLeft: 2,
                  fontSize: "9pt",
                  float: "right",
                  width: 14,
                  height: 14
                }}> 
                <i className="fa fa-pencil-square-o" style={{left:1, position: "relative"}}></i>
              </div>)}
            </Bubble>
          </TextStepContainer>
        </div>
        {help && (<>
          <a href="#" className="toggle_help">
            <ImageHelp aria-hidden="true" style={{left: showAvatar ? 60 : 17}}> 
              <i className="fa fa-question-circle" style={{left: 2, position: "relative", fontSize: "14pt"}}></i>
            </ImageHelp>
          </a>
          <div className="appear onoff_help" style={{left: showAvatar ? 56 : 10}}>{help}</div>
        </>)}
      </>
    );
  }
}

TextStep.propTypes = {
  avatarStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  bubbleStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  hideBotAvatar: PropTypes.bool.isRequired,
  hideUserAvatar: PropTypes.bool.isRequired,
  previousStep: PropTypes.objectOf(PropTypes.any),
  previousValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  speak: PropTypes.func,
  step: PropTypes.objectOf(PropTypes.any).isRequired,
  steps: PropTypes.objectOf(PropTypes.any),
  triggerNextStep: PropTypes.func.isRequired,
  triggerStep: PropTypes.func.isRequired  
};

TextStep.defaultProps = {
  previousStep: {},
  previousValue: '',
  speak: () => {},
  steps: {}
};

export default TextStep;
