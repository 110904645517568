import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

class Review extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { values } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <h3 style={{ marginTop: 0 }}>Sumário</h3>
        <table>
          <tbody style={{ fontSize: 10 }}>
            {Object.keys(values).map((key, _) => {
              if (typeof(key) != "object") {
                return <tr key={"row" + _}>
                  <td style={{fontWeight: "bold", paddingRight: 15}}>{key}</td>
                  <td>{Array.isArray(values[key]) && values[key].length > 1 ? values[key].join(', ') : values[key] == "true" ? "Sim" : values[key] == "false" ? "Não" : values[key]}</td>
                </tr>
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

export default Review;