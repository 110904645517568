import React, { Component } from 'react';
import '../styles/app.css';

class End extends Component {

  	constructor(props) {
	    super(props);
		var highScores = JSON.parse(sessionStorage.getItem("highScores")) || [];
	    const data = JSON.parse(sessionStorage.getItem("program")) || null;
	    const lastScore = sessionStorage.getItem("lastScore");
	    const lastMultiScore = JSON.parse(sessionStorage.getItem("lastMultiScore"));
	    this.state = { 
	    	program: data,
	    	highScores: highScores,
	    	lastScore: lastScore,
	    	lastMultiScore: lastMultiScore,
	    	ready: false,
	    	scoreText: 0
	    };
	}

	componentDidMount() {
	    const { program, lastScore, lastMultiScore } = this.state;
	    var finalTitle = "", finalSubtitle = "";;
	    if (program != undefined) {
		    let selected = undefined, finalRank = [];
			if (program.options.multiples) {
		        finalTitle = "Em ordem, seguem as opções que melhor se ajustam ao seu perfil!";
		        let invertedMap = {};
		        for (let i in lastMultiScore) {
		        	if (invertedMap[lastMultiScore[i]] == undefined)
		        		invertedMap[lastMultiScore[i]] = [];
		        	invertedMap[lastMultiScore[i]].push(i);
		        }
		        if (invertedMap.length == 0) {
					finalSubtitle = "Nenhuma opção foi encontrada!";
		        } else {
			        var idxs = Object.keys(invertedMap).sort((a,b)=>new Number(b) - new Number(a));
			        var max = new Number(idxs[0]), min = new Number(idxs[idxs.length-1]);
			        let count = 0;
			        for (let i in idxs) {
			        	for (let j in invertedMap[idxs[i]]) {
			        		count ++;
			        		finalRank.push({"position": count, "name": invertedMap[idxs[i]][j], "score": max == min ? 100 : Math.round(1000*(new Number(idxs[i]) - min)/(max-min))/10 })
					        if (program.options.ranking != undefined) {
					        	if (count == program.options.ranking)
					        		break;
					        }
			        	}
				        if (program.options.ranking != undefined) {
				        	if (count == program.options.ranking)
				        		break;
				        }
			        }
			        finalSubtitle = "<table style='width: 80vw;padding: 50px 20px;'>";
					finalSubtitle += "<tr style='height: 44px;vertical-align: middle;font-size: 14pt;'><th>#</th><th>Pontuação</th><th>Opção</th></tr>";
					let cellstyle = "height: 34px;border: 1px solid black; padding: 0px 5px 0px 7px;font-size: 12pt; background: white";
			        for (let i in finalRank)
						finalSubtitle += "<tr><td style='" + cellstyle + "'>" + finalRank[i]["position"] + "º</td>" + 
										 "<td style='" + cellstyle + "'>" + finalRank[i]["score"] + "</td>" + 
										 "<td style='" + cellstyle + "'><b>" + finalRank[i]["name"] + "</b></td></tr>";
			        finalSubtitle += "</table>";
		        }
			} else {
			    let valScore = new Number(lastScore);
			    for (let i in program.evaluation) {
			        let values = i.substring(1,i.length-1).split("-");
			        let minimum = new Number(values[0]);
			        let maximum = new Number(values[1]);
			        if (i.startsWith("[") && i.endsWith("]")) {
			            if (minimum <= valScore && valScore <= maximum) {
			                selected = i;
			                break;
			            }
			        }
			        else if (i.startsWith("]") && i.endsWith("]")) {
			            if (minimum < valScore && valScore <= maximum) {
			                selected = i;
			                break;
			            }
			        }
			        else if (i.startsWith("]") && i.endsWith("[")) {
			            if (minimum < valScore && valScore < maximum) {
			                selected = i;
			                break;
			            }
			        }
			        else {
			            if (minimum <= valScore && valScore < maximum) {
			                selected = i;
			                break;
			            }
			        }
			    }
			    if (selected != undefined) {
			        finalTitle = program.evaluation[selected].title.replaceAll("${score}", lastScore);
			        finalSubtitle = program.evaluation[selected].explanation.replaceAll("${score}", lastScore);;
			    } else {
			        finalTitle = "Sua pontuação final foi " + lastScore;
			    }
			}

		}
		this.setState({ready:true, finalTitle: finalTitle, finalSubtitle: finalSubtitle});
	}

	saveHighScore = e => {
		const { lastScore } = this.state;
	    var { highScores } = this.state;
	    e.preventDefault();
	    const score = {
	        score: lastScore,
	        name: this.state.username
	    };
	    highScores.push(score);
	    highScores.sort((a, b) => b.score - a.score);
	    highScores.splice(8);
	    sessionStorage.setItem("highScores", JSON.stringify(highScores));
	    window.location.assign("/quiz");
	}

	render() {
		const {ready, program, username, finalTitle, finalSubtitle} = this.state;
		if (!ready) {
			return <table className="commonQuiz">
				<tbody>
					<tr>
						<td className="cell-body">
					    	<div className="container">
				                <div id="loader" key="loader"></div>
				            </div>
			    		</td>
			    	</tr>
			    </tbody>
		    </table>
		}
		return <table className="commonQuiz">
			<tbody>
				<tr>
					<td className="cell-body">
				    	<div className="container">
							<div key="end" class="flex-center flex-column">
			                    <h2 className="h2Quiz" key="finalTitle" style={{fontSize: "2.75rem"}} dangerouslySetInnerHTML={{ __html: finalTitle }}></h2>
			                    <h3 className="h3Quiz" key="finalSubtitle" style={{marginBottom: 30, fontSize: "2rem", textAlign: "justify"}} dangerouslySetInnerHTML={{ __html: finalSubtitle }}></h3>
			                    {program.options.ranking && !program.options.multiples && <div key="highscores">
			                        <form className="formQuiz">
			                            <input
			                                type="text"
			                                name="username"
			                                key="username"
			                                placeholder="Nome:"
			                                onKeyUp={(e)=>this.setState({username:e.target.value})}
			                                className="inputQuiz"
			                            />
			                            <button 
			                                type="submit" 
			                                class="btn" 
			                                key="saveScoreBtn" 
			                                onClick={(event)=>this.saveHighScore(event)}
			                                disabled={!username || username == ""}
			                            >
			                                Salvar 
			                            </button>
			                        </form>
			                    </div>}
			                    <a class="btn" href="/quiz">Jogar Novamente</a>
			                </div>
				    	</div>
		    		</td>
		    	</tr>
		    </tbody>
	    </table>
	}
}

export default End;