import React, { Component } from 'react';
import '../styles/app.css';
import '../styles/highscores.css';

class Highscores extends Component {

  	constructor(props) {
	    super(props);
		var highScores = JSON.parse(sessionStorage.getItem("highScores")) || [];
	    this.state = { 
	    	highScores: highScores,
	    };
	}

	render() {
		if (!this.state.highScores)
			return null;
		return <table className="commonQuiz">
			<tbody>
				<tr>
					<td className="cell-body">
				    	<div className="container">
	    	                <div id="highscores" class="flex-center flex-column">
			                    <h1 className="h1Quiz" id="finalScore">Ranking</h1>
			                    <ul id="highScoresList">
									{this.state.highScores.map(score => {
								        return <li class="high-score">{score.name + " - " + score.score}</li>;
								    })}
			                    </ul>
			                    <a class="btn" href="/quiz">Ir para inicio</a>
			                </div>
				    	</div>
		    		</td>
		    	</tr>
		    </tbody>
	    </table>
	}
}

export default Highscores;