import styled from 'styled-components';
import { scale } from '../../common/animations';

const ImageHelp = styled.div`
  animation: ${scale} 0.3s ease forwards;
  height: 20px;
  min-width: 20px;
  transform: scale(0);
  transform-origin: ${props => (props.user ? 'bottom left' : 'bottom right')};
  width: 20px;
  top: -20px;
  background: white;
  border-radius: 20px;
  position: relative;
`;

export default ImageHelp;
