const local = window.location.host == "localhost";

export function frontend() {
	return {
		path: local ? "http://localhost" : "https://www.chatisfact.com.br",
		port: ""
	}
}

export function backend() {
	return {
		path: local ? "http://localhost" : "https://www.chatisfact.com.br/api",
		port: local ? ":8000/api" : ""
	}
} 
