import React from 'react';
import Dialog from './Dialog'
import teste from './data/teste.json'
import FolderList from './FolderList'
import { backend } from './config.js'
import { useLocation } from "react-router-dom";

function Main() {
	try {
		var oReq = new XMLHttpRequest();
		oReq.open("GET", backend().path + backend().port + "/list", false);
		oReq.send();
		var data = JSON.parse(oReq.responseText);
		return FolderList(data);
	} catch(error) {
		console.error("ERROR: " + error.message)
		if (error.message.startsWith("Failed to execute 'send' on 'XMLHttpRequest': Failed to load"))
	    	return <div className="row" style={{position:"absolute", left:"10vw", top:"10vh", width:"80vw",height:"80vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "80vh", fontSize: "xx-large"}}>
	    		Servidor do backend está fora do ar.
	    	</div>
	    else
	    	return <div className="row" style={{position:"absolute", left:"10vw", top:"10vh", width:"80vw",height:"80vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "80vh", fontSize: "xx-large"}}>
	    		Nenhum chatbot foi encontrado.
	    	</div>
	}
}

export default Main;