import React from 'react';
import Dialog from './Dialog'
import { backend } from './config.js'
import { useLocation } from "react-router-dom";

function Bot() {
	let idBot = useQuery(); 
	return loadDialog(idBot.get("id"));
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function loadDialog(idBot, superProps) {
	try {
		var oReq = new XMLHttpRequest();
		oReq.open("GET", backend().path + backend().port + "/open?id=" + idBot, false);
		oReq.send();
		var data = JSON.parse(oReq.responseText);
		var info = data.info ? data.info : {};
		if (superProps) {
			for (let i in superProps) {
				info[i] = superProps[i];
			}
		}
		if (data == undefined || data.code == undefined) {
			return <div className="row" style={{position:"absolute", left:"10vw", top:"10vh", width:"80vw",height:"80vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "80vh", fontSize: "xx-large"}}>
	    		Nenhum chatbot foi encontrado.
	    	</div>
		}
		var code = data.code;
		return <Dialog
			recognitionEnable={info.recognitionEnable}
			speechSynthesis={info.speechSynthesis}
			hideUserAvatar={info.hideUserAvatar}
			hideBotAvatar={info.hideBotAvatar}
			botFontColor={info.botFontColor}
			headerFontColor={info.headerFontColor}
			userFontColor={info.userFontColor}
			botBubbleColor={info.botBubbleColor}
			userBubbleColor={info.userBubbleColor}
			optionBubbleColor={info.optionBubbleColor}
			background={info.background}
			headerBgColor={info.headerBgColor}
			floating={info.floating}
			botDelay={info.botDelay}
			width={info.width}
			height={info.height}
			avatar={info.avatar}
			cache={info.cache}
			fullscreen={info.fullscreen}
			title={code.title}
			route={code.route}
			id={idBot}
		/>
	} catch(error) {
		console.error("ERROR: " + error.message)
		if (error.message.startsWith("Failed to execute 'send' on 'XMLHttpRequest': Failed to load"))
	    	return <div className="row" style={{position:"absolute", left:"10vw", top:"10vh", width:"80vw",height:"80vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "80vh", fontSize: "xx-large"}}>
	    		Servidor do backend está fora do ar.
	    	</div>
	    else
			return <div className="row" style={{position:"absolute", left:"10vw", top:"10vh", width:"80vw",height:"80vh", background:"#EEE", textAlign: "center", verticalAlign: "middle", lineHeight: "80vh", fontSize: "xx-large"}}>
	    		Nenhum chatbot foi encontrado.
	    	</div>
	}
}

export default Bot;